h1.heading {
    font-size: 5rem;
    margin-bottom: 1rem;
}

img.logo {
    height: 10rem;
    margin-bottom: 4rem;
}

@media screen and (max-width: 599px) {
    h1.heading {
        font-size: 5rem;
        margin-bottom: 1rem;
    }

    img.logo {
        height: 8rem;
        margin-bottom: 2rem;
    }
}

@media screen and (min-width: 900px) {
    h1.heading {
        font-size: 5rem;
    }

    img.logo {
        height: 15rem;
        margin-bottom: 5rem;
    }
}

@media screen and (min-width: 1200px) {
    h1.heading {
        font-size: 6rem;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }
    img.logo {
        height: 20rem;
        margin-bottom: 5vh;
    }
}
